<template>
  <div
    v-ripple.400
    class="ticket"
    :class="{ selected : ticket.status === 'Reserved', sold : ticket.status === 'Sold' }"
    @click="ticket.status != 'Reserved' ? reserve(ticket.id) : release(ticket.id)"
  >
    <div
      class="avatar"
      :class="{ showAvatar : ticket.status === 'Reserved' }"
    >
      <b-img
        :src="require(`@/assets/images/logo/SmallLogo3.png`)"
      />
    </div>
    {{ ticket.folio }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  props: {
    ticket: {
      type: Object,
      default: () => ({
        folio: 0,
        status: 'Available',
        id: '',
        price: 0,
      }),
    },
  },
  data() {
    return {
      selected: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    ...mapActions('tickets', ['reserveTicket', 'releaseTicket']),
    reserve() {
      if (this.currentUser !== null) {
        this.reserveTicket({
          roomId: this.$route.params.id,
          ticketId: this.ticket.id,
          userId: this.currentUser.id,
        })
      } else {
        this.$bvModal.show('loginModal')
      }
    },
    release() {
      this.releaseTicket({
        roomId: this.$route.params.id,
        ticketId: this.ticket.id,
        userId: this.currentUser.id,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.ticket {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: hsl(244, 100%, 97%);
  height: 65px;
  width: 170px;
  margin: 0.8rem;
  transition: all .20s;
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background: $primary;
    font-weight: 900;
    color: white;
    font-size: 20px;
    box-shadow: 0px 0px 8px $primary;
  }
}
.disabled {
  background: $secondary;
  font-weight: 900;
  color: white;
  font-size: 20px;
  box-shadow: 0px 0px 15px $secondary !important;
}

.selected {
  background: $primary;
  font-weight: 900;
  color: white;
  font-size: 20px;
  box-shadow: 0px 0px 15px $primary !important;
}

.sold {
  background: $warning !important;
  font-weight: 900;
  color: white;
  font-size: 20px;
  box-shadow: 0px 0px 15px $warning !important;
}

.avatar{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -25px;
  left: 1rem;
  margin-top: -20px;
  transition: top 0.3s ease;
  background: #F6AE07;
  box-sizing: border-box;
  border: 3px solid #F7E2AB;
  &.showAvatar {
    top: 50%;
  }
  img {
    width: 23px;
  }
}

@media screen and (max-width: 880px) {
  .ticket {
    border-radius: 10px;
    height: 50px;
    width: 100px;
    margin: 0.7rem;
    font-size: .9rem;
  }
}
@media screen and (max-width: 480px) {
  .ticket {
    border-radius: 10px;
    height: 40px;
    width: 70px;
    margin: 0.7rem;
    font-size: .9rem;
  }
}

</style>
