<template>
  <b-modal
    id="loginModal"
    hide-footer
  >
    <b-link class="brand-logo d-flex justify-content-center">
      <b-img
        src="@/assets/images/logo/logo2_square.png"
        height="90px"
        width="180px"
        fluid
        alt="Logo"
      />
    </b-link>

    <div class="mb-1 h3">
      Bienvenido a TicketCrush!
    </div>
    <div class="mb-2 h5">
      Porfavor ingresa a tu cuenta para comenzar la experiencia
    </div>

    <!-- form -->
    <validation-observer
      ref="loginForm"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="login()"
      >

        <!-- email -->
        <b-form-group
          label-for="email"
          label="Correo eléctronico"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="userEmail"
              name="login-email"
              :state="errors.length > 0 ? false:null"
              placeholder="john@example.com"
              autofocus
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="password">Contraseña</label>
            <b-link :to="{name:'forgot-password'}">
              <small>¿Olvidaste tu contraseña?</small>
            </b-link>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password"
                v-model="password"
                :type="passwordFieldType"
                class="form-control-merge"
                :state="errors.length > 0 ? false:null"
                name="login-password"
                placeholder="Password"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit button -->
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
        >
          Iniciar sesión
        </b-button>
      </b-form>
    </validation-observer>

    <div class="text-center mt-2">
      <span>¿Eres nuevo? </span>
      <b-link :to="{name:'auth-register'}">
        <span>Crear cuenta</span>
      </b-link>
    </div>

    <div class="divider my-2">
      <div class="divider-text">
        ó
      </div>
    </div>

    <!-- social button -->
    <div class="auth-footer-btn d-flex justify-content-center mb-2">
      <b-button
        variant="facebook"
        class="mr-2"
        @click="loginWithFacebook()"
      >
        <feather-icon icon="FacebookIcon" />
      </b-button>
      <b-button
        variant="google"
        @click="loginWithGoogle()"
      >
        <feather-icon icon="MailIcon" />
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import {
  BForm,
  BFormInput,
  BButton,
  BLink,
  BModal,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapMutations } from 'vuex'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BForm,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BLink,
    BModal,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    const googleScript = document.createElement('script')
    googleScript.setAttribute('src', 'https://apis.google.com/js/client:platform.js?onload=start')
    document.head.appendChild(googleScript)
    const facebookScript = document.createElement('script')
    facebookScript.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
    document.head.appendChild(facebookScript)
    window.fbAsyncInit = () => {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: '1841241999491500',
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v12.0',
      })
    }
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    ...mapMutations('auth', ['setCurrentUser']),

    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.signIn({ email: this.userEmail, password: this.password })
            .then(response => {
              const userData = response
              useJwt.setToken(response.token)
              useJwt.setRefreshToken(response.refresh_token)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.setCurrentUser(response)
              this.$bvModal.hide('loginModal')
            })
        }
      })
    },
    loginWithFacebook() {
      // eslint-disable-next-line no-undef
      FB.login(response => {
        if (response.authResponse) {
          axios.get('users/auth/facebook/callback', { withCredentials: true })
            .then(resp => {
              /* eslint-disable-next-line */
              console.log(resp)
            })
            .catch(error => {
              /* eslint-disable-next-line */
              console.log(error)
            })
        } else {
          /* eslint-disable-next-line */
          console.log('User cancelled login or did not fully authorize.')
        }
      })
    },
    async loginWithGoogle() {
      const googleUser = await this.$gAuth.getAuthCode()
      if (googleUser) {
        axios.get(`users/auth/google_oauth2/callback?code=${googleUser}`, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
          .then(response => {
            const userData = response.data
            useJwt.setToken(response.data.token)
            useJwt.setRefreshToken(response.data.refresh_token)
            localStorage.setItem('userData', JSON.stringify(userData))
            this.setCurrentUser(response)
            this.$bvModal.hide('loginModal')
          })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
