<template>
  <div class="pt-1">
    <home-menu :bgwhite="true" />
    <b-container class="mt-5">
      <b-row class="match-height">
        <b-col
          sm="4"
          class=""
        >
          <raffle-main-card />
        </b-col>
        <b-col
          sm="8"
        >
          <raffle-gallery
            :raffle="currentRaffle"
            class="raffle-gallery"
          />
        </b-col>
      </b-row>
      <div class="d-flex align-items-center justify-content-center my-4">
        <raffle-timer
          class="timer"
          :expires-date="currentRaffle.raffle_date"
        />
      </div>
      <div
        v-if="new Date < new Date(currentRaffle.raffle_date)"
        class="text-center"
      >
        <h3
          class="display-4 mb-1 h3-title"
        >
          Selecciona tús boleto
        </h3>
        <ticket-select-grid />
      </div>
      <div class="text-center mt-5">
        <p class="mb-0">
          Otros usuarios también compraron
        </p>
        <h3
          class="display-4 h3-title"
        >
          Sorteos relacionados
        </h3>
      </div>
      <raffle-slides />
    </b-container>
    <LoginModal />
  </div>
</template>

<script>
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import HomeMenu from '@/views/home/HomeMenu.vue'
import TicketSelectGrid from '@/@core/components/tickets/TicketSelectGrid.vue'
import RaffleGallery from '@/views/raffles/RaffleGallery.vue'
import RaffleMainCard from '@/views/raffles/RaffleMainCard.vue'
import RaffleSlides from '@/@core/components/raffles/RaffleSlides.vue'
import RaffleTimer from '@/@core/components/RaffleTimer.vue'
import LoginModal from '@/components/LoginModal.vue'

export default {
  components: {
    HomeMenu,
    TicketSelectGrid,
    RaffleGallery,
    RaffleSlides,
    RaffleTimer,
    RaffleMainCard,
    LoginModal,
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  computed: {
    ...mapGetters('tickets', ['cart']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('raffles', ['currentRaffle']),
    ...mapGetters('rooms', ['currentRoom']),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.id': function (value) {
      this.getRaffle(value)
        .then(() => {
          this.getClientRaffles({
            by_active_status: true,
            by_category: this.currentRaffle.categories[0].id,
          })
        })
      this.getRoom(value)
    },
  },
  mounted() {
    this.getRaffle(this.$route.params.id)
      .then(() => {
        this.getClientRaffles({
          by_active_status: true,
          by_category: this.currentRaffle.categories[0].id,
        })
      })
    this.getRoom(this.$route.params.id)
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)

    const connection = new HubConnectionBuilder()
      .withUrl('https://reservations.ticketcrush.com.mx/roomshub')
      .configureLogging(LogLevel.Information)
      .build()

    async function start() {
      try {
        await connection.start()
      } catch (err) {
        setTimeout(start, 5000)
      }
    }

    connection.on(this.$route.params.id, (sender, messageText) => {
      this.UPDATE_TICKET(messageText)
      if (messageText.status === 'Reserved') {
        this.getUserCart({ userId: this.currentUser.id })
        // this.ADD_RESERVED_TICKET_TO_CART(messageText.ticketId)
      } else if (messageText.status === 'Available') {
        this.getUserCart({ userId: this.currentUser.id })
        // this.REMOVE_TICKET_FROM_CART(messageText.ticketId)
      }
    })

    connection.onclose(async () => {
      await start()
    })

    // Start the connection.
    start()
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    this.RESET_RAFFLE()
    this.RESET_RAFFLES()
  },
  methods: {
    ...mapActions('raffles', ['getRaffle', 'getClientRaffles']),
    ...mapActions('rooms', ['getRoom']),
    ...mapActions('tickets', ['checkout', 'getUserCart']),
    ...mapMutations('raffles', ['RESET_RAFFLE', 'RESET_RAFFLES']),
    ...mapMutations('rooms', ['UPDATE_TICKET']),
    ...mapMutations('tickets', ['ADD_RESERVED_TICKET_TO_CART', 'REMOVE_TICKET_FROM_CART']),
  },
}
</script>

<style lang="scss" scoped>
.timer  {
  position: relative;
  &::after{
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    background: url('../../assets/images/SquareDots.png');
    background-size: cover;
    top: -25px;
    right: -60px;
    z-index: -1;
  }
  &::before{
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    background: url('../../assets/images/SquareDots.png');
    background-size: cover;
    bottom: -15px;
    left: -60px;
  }
}
@media screen and (max-width: 880px) {
  .h3-title {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 580px) {
  .h3-title {
    font-size: 2rem;
  }
  .raffle-gallery {
    margin-top: 1rem;
  }
}
</style>
