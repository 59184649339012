<template>
  <swiper
    class="swiper-responsive-breakpoints"
    :options="swiperOptions"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
  >
    <swiper-slide
      v-for="(data,index) in raffles"
      :key="index"
      class="pt-5 pb-2"
    >
      <b-card
        no-body
        class="card-bg rounded-corners"
      >
        <b-card-body class="text-center p-1">
          <b-card-title class="mb-0 font-weight-bolder">
            {{ data.name }}
          </b-card-title>
          <!-- <b-card-sub-title class="mt-0">
            {{ data.description }}
          </b-card-sub-title> -->
        </b-card-body>
        <b-img
          fluid
          :src="data.thumbnails[0]"
          alt="Card image cap"
        />
        <b-card-body class="text-center p-1">
          <!-- <p class="text-bolder text-small m-0">
            La venta termina el <b>{{ data.raffle_date | dateNtime }}</b>
          </p> -->
          <b-button
            tag="a"
            class="btn-cart btn-gradient"
            block
            variant="gradient-primary"
            @click="$router.push({ name: 'raffle', params: { id: data.id } })"
          >
            <span v-if="new Date() > new Date(data.raffle_date) && !data.winner_user">Sorteo finalizado</span>
            <span v-else-if="data.winner_user">Ver el ganador</span>
            <span v-else>Comprar</span>
          </b-button>
        </b-card-body>
      </b-card>
    </swiper-slide>

    <!-- Add Arrows -->
    <div
      slot="button-next"
      class="swiper-button-next"
    />
    <div
      slot="button-prev"
      class="swiper-button-prev"
    />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {

      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1400: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('raffles', ['raffles']),
  },
  created() {
  },
  methods: {
  },
}
</script>
<style lang="scss">
.card-bg{
  background-color: #F1F0FF;
}
.text-small {
  font-size: 11px;
}
</style>
