<template>
  <div class="base-timer">
    <span
      id="timer"
      class="base-timer__label"
    >
      {{ timer }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      timer: '',
    }
  },
  computed: {
    ...mapGetters('raffles', ['currentRaffle']),
  },
  watch: {
    currentRaffle(raffle) {
      // Set the date we're counting down to
      const countDownDate = new Date(raffle.raffle_date).getTime()
      // Update the count down every 1 second
      const x = setInterval(() => {
        // Get today's date and time
        const now = new Date().getTime()
        // Find the distance between now and the count down date
        const distance = countDownDate - now
        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24))
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)
        // Output the result in an element with id="demo"

        this.timer = `${days}d ${hours}h:${minutes}m:${seconds}s`
        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(x)
          this.timer = 'Sorteo finalizado'
        }
      }, 1000)
    },
  },
  created() {

  },
  methods: {

  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';
.base-timer {
  position: relative;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    padding: 1.5rem 2rem;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 425px;
    font-size: 40px;
    border-radius: 60px;
    color: white;

  }
  @media screen and (max-width: 450px) {
    .base-timer__label {
      padding: 1.5rem 2rem;
      min-width: 325px;
      font-size: 30px;
      border-radius: 60px

    }
  }
}
</style>
