<template>
  <b-card
    no-body
    class="card-bg rounded-corners mb-0"
  >
    <b-embed
      v-if="new Date() > new Date(currentRaffle.raffle_date) && !currentRaffle.winner_user"
      type="iframe"
      aspect="16by9"
      :src="`https://www.youtube.com/embed/${currentRaffle.url}`"
      allowfullscreen
    />
    <div
      v-else-if="currentRaffle.winner_user"
      class="winner-card flex-column"
    >
      <h1 class="text-white mb-2">
        ¡Ganador!
      </h1>
      <h4 class="text-white mb-2">
        {{ currentRaffle.winner_user.name }}
      </h4>
      <b-img
        fluid
        rounded="circle"
        :src="currentRaffle.winner_user.logo"
        alt="Circle image"
        width="45"
      />
    </div>
    <b-img
      v-else-if="currentRaffle.thumbnails && !currentRaffle.winner_user"
      fluid
      :src="currentRaffle.thumbnails[0]"
      alt="Card image cap"
      class="rounded-corners"
    />
    <b-card-body class="p-2">
      <h2 class="font-weight-bolder">
        {{ currentRaffle.name }}
      </h2>
      <p class="text-bolder h4 mb-3">
        {{ currentRaffle.description }}
      </p>
      <b-row>
        <b-col>
          <h2 class="h5">
            Fecha del sorteo
          </h2>
          <p class="text-bolder h3 mb-2">
            {{ currentRaffle.raffle_date | dateNtime }}
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!currentRaffle.winner_user">
        <b-col>
          <h2 class="h5">
            Precio por boleto
          </h2>
          <p class="text-bolder h2 mb-2">
            <i class="fas fa-coins" /> {{ currentRaffle.price_of_ticket }}
          </p>
        </b-col>
        <b-col>
          <h2 class="h5">
            Boletos disponibles
          </h2>
          <p class="text-bolder h3 mb-2">
            {{ currentRaffle.available_tickets }} <span class="text-muted"> de {{ currentRaffle.number_of_tickets }}</span>
          </p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col class="pt-2 d-flex justify-content-center align-items-center">
          <div class="ticket">
            {{ currentRaffle.winner_ticket }}
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('raffles', ['currentRaffle']),
    ...mapGetters('rooms', ['currentRoom']),
  },
  methods: {
    async share() {
      const shareData = {
        title: 'MDN',
        text: 'Learn web development on MDN!',
        url: `https://toctocticket.com/${this.$route.params.id}`,
      }

      try {
        await navigator.share(shareData)
        this.shared = true
      } catch (err) {
        // console.log(`Error: ${err}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.winner-card{
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  color: white;
  background: rgb(147,80,219);
  background: linear-gradient(90deg, rgba(147,80,219,1) 0%, rgba(87,37,189,1) 100%);
}
.rounded-corners {
  border-radius: 1rem;
  overflow: hidden;
}
@import '~@core/scss/base/core/colors/palette-variables.scss';
.ticket {
    width: 150px;
    height: 70px;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 30px;
    color: white;
    border-radius: 4px;
    position: relative;
    animation: pulse 8s ease infinite;

    &::before {
      content: '';
      width: 30px;
      height: 30px;
      background: #F1F0FF;
      border-radius: 50%;
      position: absolute;
      right: -15px;
      top: calc(50% - 15px);
    }
    &::after {
      content: '';
      width: 30px;
      height: 30px;
      background: #F1F0FF;
      border-radius: 50%;
      position: absolute;
      left: -15px;
      top: calc(50% - 15px);
    }
    @keyframes pulse {
      50% {
        transform: scale(110%) rotate(-3deg);
      }
    }
}
</style>
