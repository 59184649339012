<template>
  <div>
    <b-pagination-nav
      v-model="currentPage"
      align="center"
      :pages="ticketStates"
      hide-goto-end-buttons
    />
    <div class="scroll-fix">
      <div class="tickets-container">
        <ticket
          v-for="(ticket, n) in currentRoom.tickets.filter(x => {
            if(ticketStates[currentPage-1].key != null) {
              return x.status === ticketStates[currentPage-1].key
            } else {
              return true
            }
          })"
          :key="`ticket-${n}`"
          :ticket="ticket"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BPaginationNav,
} from 'bootstrap-vue'
import Ticket from '@core/components/tickets/Ticket.vue'

export default {
  components: {
    Ticket,
    BPaginationNav,
  },
  data() {
    return {
      selected: false,
      currentPage: 1,
      ticketStates: [
        {
          text: 'Todos',
          key: null,
        },
        {
          text: 'Disponibles',
          key: 'Available',
        },
        {
          text: 'Seleccionados',
          key: 'Reserved',
        },
        {
          text: 'Comprados',
          key: 'Sold',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('rooms', ['currentRoom']),
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.tickets-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-height: 600px;
  overflow-x: auto;
}
.scroll-fix {

  /* width */
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #F1F0FF;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #BEBAFF;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }

  @media screen and (max-width: 480px) {
    ::-webkit-scrollbar {
      width: 5px;
    }
  }
}
</style>
