<template>
  <div>
    <!-- swiper1 -->
    <swiper
      ref="swiperTop"
      class="swiper-gallery gallery-top mb-1"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-if="raffle.winner_user"
        class="rounded-corners"
      >
        <div class="winner-card flex-column">
          <h1 class="text-white mb-2">
            ¡Ganador!
          </h1>
          <h4 class="text-white mb-2">
            {{ raffle.winner_user.name }}
          </h4>
          <b-img
            fluid
            rounded="circle"
            :src="raffle.winner_user.logo"
            alt="Circle image"
            width="180"
          />
        </div>
      </swiper-slide>
      <swiper-slide
        v-for="(data,index) in raffle.optimized_images"
        :key="index"
        class="rounded-corners"
      >
        <b-img
          :src="data"
          fluid
        />
      </swiper-slide>
      <swiper-slide
        v-if="new Date() > new Date(raffle.raffle_date) && raffle.url"
        class="rounded-corners"
      >
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="`https://www.youtube.com/embed/${raffle.url}`"
          allowfullscreen
        />
      </swiper-slide>

      <div
        slot="button-next"
        class="swiper-button-next swiper-button-white"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev swiper-button-white"
      />
    </swiper>

    <!-- swiper2 Thumbs -->
    <swiper
      ref="swiperThumbs"
      class="swiper gallery-thumbs"
      :options="swiperOptionThumbs"
    >
      <swiper-slide
        v-if="raffle.winner_user"
        class="rounded-corners"
      >
        <b-img
          :src="require('@/assets/images/winner.svg')"
          fluid
        />
      </swiper-slide>
      <swiper-slide
        v-for="(data,index) in raffle.optimized_images"
        :key="index"
        class="rounded-corners"
      >
        <b-img
          :src="data"
          fluid
        />
      </swiper-slide>
      <swiper-slide
        v-if="new Date() > new Date(raffle.raffle_date) && raffle.url"
        class="rounded-corners"
      >
        <b-img
          :src="require('@/assets/images/stream.svg')"
          fluid
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg, BEmbed } from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BEmbed,
  },
  props: {
    raffle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: false,
        slidesPerView: 5,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
      const swiperThumbs = this.$refs.swiperThumbs.$swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },
}
</script>

<style lang="scss" scoped>
.rounded-corners {
  border-radius: 1rem;
  overflow: hidden;
}
.winner-card{
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  color: white;
  background: rgb(147,80,219);
  background: linear-gradient(90deg, rgba(147,80,219,1) 0%, rgba(87,37,189,1) 100%);
}
</style>
